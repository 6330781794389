var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card-title',{staticClass:"mb-1",attrs:{"title-tag":"h1"}},[_vm._v(" "+_vm._s(_vm.$t('pages.register.title'))+" 👤 ")]),_c('b-card-text',{staticClass:"mb-5"},[_vm._v(" "+_vm._s(_vm.$t('pages.register.subtitle'))+" ")]),_c('validation-observer',{ref:"registerFormRules",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.validateForm($event)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Email'),"label-for":"user-email"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Email'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"user-email","state":errors.length > 0 ? false:null,"name":"user-email","autocomplete":"email","tabindex":"1","trim":""},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"new-password"}},[_vm._v(_vm._s(_vm.$t('Password')))])]),_c('validation-provider',{attrs:{"name":"newPassword","vid":"newPassword","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{ref:"newPassword",staticClass:"form-control-merge",attrs:{"id":"new-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"new-password","autocomplete":"new-password","tabindex":"1","trim":""},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"confirm-password"}},[_vm._v(_vm._s(_vm.$t('pages.register.confirmPassword')))])]),_c('validation-provider',{attrs:{"name":"confirmPassword","vid":"confirmPassword","rules":("required|confirmpassword:" + _vm.newPassword)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"confirm-password","state":errors.length > 0 ? false:null,"type":_vm.passwordFieldType,"name":"confirm-password","autocomplete":"new-password","tabindex":"2","trim":""},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{staticClass:"mt-5 d-flex align-items-center justify-content-center mb-3",attrs:{"type":"submit","variant":"primary","block":"","disabled":invalid || _vm.loading,"tabindex":"3"}},[(_vm.loading)?_c('b-spinner',{staticClass:"mr-2",attrs:{"small":""}}):_vm._e(),_c('span',{staticClass:"py-1"},[_vm._v(" "+_vm._s(_vm.$t('pages.register.button'))+" ")])],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-4"},[_c('b-link',{attrs:{"to":{name:'foravila-login'},"tabindex":"4"}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('pages.register.goToLogin'))+" ")],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }