<template>
  <security-page>
    <template #left>
      <page-illustration :src="illustration" />
    </template>
    <template #right>
      <register-form />
    </template>
  </security-page>
</template>

<script>
import SecurityPage from '@/views/foravila/security/components/SecurityPage.vue'
import PageIllustration from '@/views/foravila/security/components/PageIllustration.vue'
import RegisterForm from '@/views/foravila/security/components/RegisterForm.vue'

export default {
  components: {
    SecurityPage,
    PageIllustration,
    RegisterForm,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      illustration: require('@/assets/foravila/images/illustrations/new-user.svg'),
    }
  },
}
</script>
