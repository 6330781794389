<template>
  <div>
    <b-card-title
      title-tag="h1"
      class="mb-1"
    >
      {{ $t('pages.register.title') }} 👤
    </b-card-title>

    <b-card-text class="mb-5">
      {{ $t('pages.register.subtitle') }}
    </b-card-text>

    <validation-observer
      ref="registerFormRules"
      #default="{invalid}"
    >
      <b-form @submit.prevent="validateForm">
        <!-- email -->
        <b-form-group
          :label="$t('Email')"
          label-for="user-email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t('Email')"
            rules="required|email"
          >
            <b-form-input
              id="user-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false:null"
              name="user-email"
              autocomplete="email"
              tabindex="1"
              trim
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- new password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="new-password">{{ $t('Password') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="newPassword"
            vid="newPassword"
            rules="required|password"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="new-password"
                ref="newPassword"
                v-model="newPassword"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="new-password"
                autocomplete="new-password"
                tabindex="1"
                trim
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- confirm password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="confirm-password">{{ $t('pages.register.confirmPassword') }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            name="confirmPassword"
            vid="confirmPassword"
            :rules="`required|confirmpassword:${newPassword}`"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="confirm-password"
                v-model="confirmPassword"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="confirm-password"
                autocomplete="new-password"
                tabindex="2"
                trim
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-button
          class="mt-5 d-flex align-items-center justify-content-center mb-3"
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
          tabindex="3"
        >
          <b-spinner
            v-if="loading"
            small
            class="mr-2"
          />
          <span class="py-1">
            {{ $t('pages.register.button') }}
          </span>
        </b-button>
      </b-form>
    </validation-observer>

    <p class="text-center mt-4">
      <b-link
        :to="{name:'foravila-login'}"
        tabindex="4"
      >
        <feather-icon
          icon="ChevronLeftIcon"
          class="mr-1"
        />
        {{ $t('pages.register.goToLogin') }}
      </b-link>
    </p>
  </div>
</template>

<script>
import {
  BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BCardText, BCardTitle, BForm, BButton, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import {
  required, email, password, confirmpassword,
} from '@foravila-validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { notifyError } from '@/utils/methods'

export default {
  components: {
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BSpinner,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      userEmail: null,
      newPassword: null,
      confirmPassword: null,
      required,
      email,
      password,
      confirmpassword,
    }
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale.substring(0, 2).toLowerCase()
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    emptyForm() {
      return !this.userEmail
        && !this.newPassword
        && !this.confirmPassword
    },
    urlToken() {
      return this.$route.params.token || null
    },
    localStorageToken() {
      return localStorage.getItem('foravilaRegisterToken')
    },
  },
  watch: {
    currentLanguage() {
      // ! Working but improvement needed
      // TODO: we use nextTick to allow vee-validate to update the validation messages
      this.$nextTick(() => {
        this.switchValidationLocale()
      })
    },
  },
  created() {
    this.redirectToLoginIfNoTokens()
    if (this.urlToken) this.storeTokenAndRedirect()
    this.checkToken()
  },
  methods: {
    switchValidationLocale() {
      localize(this.currentLanguage)
      if (!this.emptyForm) this.$refs.registerFormRules.validate()
    },
    redirectToLoginIfNoTokens() {
      if (!this.urlToken && !this.localStorageToken) {
        this.$router.replace({ name: 'foravila-login' })
      }
    },
    storeTokenAndRedirect() {
      localStorage.setItem('foravilaRegisterToken', this.urlToken)
      this.$router.replace({ name: 'foravila-register' })
    },
    checkToken() {
      this.$store.dispatch('app/setAppLoading', true)
      this.$store
        .dispatch('auth/validClientToken', this.localStorageToken)
        .then(valid => {
          if (!valid) this.$router.push({ name: 'foravila-login' })
        })
        .catch(() => {
          this.$router.push({ name: 'foravila-login' })
        })
        .finally(() => this.$store.dispatch('app/setAppLoading', false))
    },
    validateForm() {
      this.$refs.registerFormRules.validate().then(success => {
        if (success) {
          this.loading = true

          this.$store
            .dispatch('auth/register', {
              token: this.localStorageToken,
              email: this.userEmail,
              plainPassword: this.newPassword,
            })
            .then(() => {
              localStorage.removeItem('foravilaRegisterToken')
              this.$router.push({ name: 'foravila-login', query: { e: this.userEmail, p: this.newPassword } })
            })
            .catch(error => {
              this.loading = false
              switch (error.status) {
                case 409:
                  notifyError(this.$t('errors.registerDuplicatedEmail.title'), this.$t('errors.registerDuplicatedEmail.text'))
                  break
                default:
                  notifyError(this.$t('errors.register.title'), this.$t('errors.register.text'))
                  break
              }
            })
        }
      })
    },
  },
}
</script>
